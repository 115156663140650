//  系统相关
function systemRouters() {
    return {
        "title":"系统设置",
        "path": "/SystemManage",
        "role": [0],
        "children": [
            {
                "title": "时间管理",
                "path": "/SystemManage/Admin",
                "isActivate": true,
                "role": [0],
                "children": [
                    {
                        "title": "上门取件时间管理",
                        "path": "/SystemManage/Admin/PickupTimeManage",
                        "isActivate": true,
                        "role": [0],
                        component: () => import(/* webpackChunkName: "about" */ '@/views/SystemManage/Admin/PickupTimeManage.vue')
                    },
                    {
                        "title": "到港标派系统配置",
                        "path": "/SystemManage/Admin/ArrivalDeliveryTimeManage",
                        "isActivate": true,
                        "role": [0],
                        component: () => import(/* webpackChunkName: "about" */ '@/views/SystemManage/Admin/ArrivalDeliveryTimeManage.vue')
                    },
                ]
            },
            {
                "title": "系统配置",
                "path": "/SystemManage/Admin/SystemConfiguration",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/SystemManage/Admin/SystemConfiguration.vue')
            },
            {
                "title": "协议配置",
                "path": "/SystemManage/Admin/ProtocolManage",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/SystemManage/Admin/ProtocolManage.vue')
            },
            {
                "title": "菜单配置",
                "path": "/SystemManage/Admin/MenuManage",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/SystemManage/Admin/MenuManage.vue')
            },
        ]
    }
}

export default systemRouters;
