//  用户相关
function userRouters() {
    return {
        "title":"用户管理",
        "path": "/UserManage",
        "role": [0],
        "children": [
            {
                "title": "用户管理",
                "path": "/UserManage/Admin/UserManage",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/UserManage/Admin/UserManage.vue')
            },
            {
                "title": "客户管理",
                "path": "/UserManage/Admin/CustomerManage",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/UserManage/Admin/CustomerManage.vue')
            },
            {
                "title": "常客管理",
                "path": "/UserManage/Admin/SuperCustomerManage",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/UserManage/Admin/SuperCustomerManage.vue')
            },
            {
                "title": "地址簿管理",
                "path": "/UserManage/Admin/Address",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/UserManage/Admin/Address.vue')
            },
            {
                "title": "收款账户管理",
                "path": "/UserManage/Admin/CollectionAccount",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/UserManage/Admin/CollectionAccount.vue')
            },
            {
                "title": "现金流水管理",
                "path": "/UserManage/Admin/CashFlow",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/UserManage/Admin/CashFlow.vue')
            },
            {
                "title": "会员积分管理",
                "path": "/UserManage/Admin/AccumulatePoints",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/UserManage/Admin/AccumulatePoints.vue')
            },
            {
                "title": "会员登录日志",
                "path": "/UserManage/Admin/MemberLoginLogs",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/UserManage/Admin/MemberLoginLogs.vue')
            },
            {
                "title": "冻结客户管理",
                "path": "/UserManage/Admin/FreezeCustomers",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/UserManage/Admin/FreezeCustomers.vue')
            }
        ]
    }
}

export default userRouters;
