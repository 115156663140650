//  组织架构相关
function organizationRouters() {
    return {
        "title": "组织架构",
        "path": "/OrganizationManage",
        "children": [
            {
                "title": "企业管理",
                "path": "/OrganizationManage/index",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/index.vue'),
                "children": [
                    {
                        "path":'',
                        component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/CompanyInfoPage.vue')
                    },
                    {
                        "path": "DepartmentInfoPage",
                        component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/DepartmentInfoPage.vue')
                    },
                ]
            },
            // {
            //     "title": "部门管理",
            //     "path": "/OrganizationManage/Admin/DepartmentManage",
            //     "isActivate": true,
            //     "role": [0],
            //     component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/Admin/DepartmentManage.vue')
            // },
            // {
            //     "title": "角色管理",
            //     "path": "/OrganizationManage/Admin/RoleManage",
            //     "isActivate": true,
            //     "role": [0],
            //     component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/Admin/RoleManage.vue')
            // },
            // {
            //     "title": "用户管理",
            //     "path": "/OrganizationManage/Admin/UserManage",
            //     "isActivate": true,
            //     "role": [0],
            //     component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/Admin/UserManage.vue')
            // },
            {
                "title": "部门管理",
                "path": "/OrganizationManage/DepartmentList",
                "isActivate": true,
                "role": [1],
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/DepartmentList.vue')
            },
            {
                "title": "角色管理",
                "path": "/OrganizationManage/RoleList",
                "isActivate": true,
                "role": [1],
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/RoleList.vue')
            },
            {
                "title": "用户管理",
                "path": "/OrganizationManage/UserList",
                "isActivate": true,
                "role": [1],
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/UserList.vue')
            },
        ]
    }
}

export default organizationRouters;
